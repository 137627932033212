@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .radioInput__checkmark {
    /* #0B937D -> #0054A7 */
    border: 1px solid #0054a7;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(5px);
    display: flex;
    align-items: center;
  }

  .radioInput:checked ~ .radioInput__checkmark::after {
    content: "";
    /* #0B937D -> #0054A7 */
    background-color: #0054a7;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 7px;
    border-radius: 50%;
    display: inline-block;
  }
}
/* 顏色被修改 */
:root {
  /* #0B937D -> #0054A7 */
  --primary-color: #0054a7;
  /* #F3F3F3 -> #3D3737 */
  --gray-dark-color: #3d3737;
  /* #9E9E9E -> #8F8F8F */
  --gray-deep-color: #8f8f8f;
  /* #777777 -> #3D3737 */
  --gray-label-color: #3D3737;

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

